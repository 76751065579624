import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-chronoblog/src/components/page/index.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const AuthorBanner = makeShortcode("AuthorBanner");
const FeedSearch = makeShortcode("FeedSearch");
const Tags = makeShortcode("Tags");
const FeedItems = makeShortcode("FeedItems");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <AuthorBanner mdxType="AuthorBanner" />
    <h2 {...{
      "id": "my-personal-blog"
    }}>{`My personal blog`}</h2>
    <p>{`After many years of procrastinating, I finally decided on a name for my personal site.`}</p>
    <p>{`For now.`}</p>
    <p>{`This site is built using `}<a parentName="p" {...{
        "href": "https://www.gatsbyjs.org"
      }}>{`Gatsby`}</a>{` and is hosted at `}<a parentName="p" {...{
        "href": "https://www.gatsbyjs.org"
      }}>{`Netlify`}</a>{`. It's currently using a theme called '`}<a parentName="p" {...{
        "href": "https://github.com/Ganevru/gatsby-theme-chronoblog"
      }}>{`Chronoblog`}</a>{`', which is nice, but the plan is to write my own theme, and basically use this site for experiments, demos, and random thoughts.`}</p>
    <hr></hr>
    <FeedSearch mdxType="FeedSearch" />
    <Tags mdxType="Tags" />
    <FeedItems mdxType="FeedItems" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      